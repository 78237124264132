import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cfe4dc9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ribbon"
}
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.ribbonEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("a", {
          href: "",
          textContent: _toDisplayString(_ctx.t$('global.ribbon.' + _ctx.ribbonEnv))
        }, null, 8 /* PROPS */, _hoisted_2)
      ]))
    : _createCommentVNode("v-if", true)
}